<template>
  <div class="WordSection1">
    <h2
      style="
        background-color: #ffffff;
        font-size: 17pt;
        font-weight: normal;
        line-height: 122%;
        margin: 18pt 0pt 4pt;
        text-align: center;
      "
    >
      <a name="_fkj8vs9qsvyq"></a
      ><span style="font-family: Nunito; font-size: 17pt"
        >General Terms &amp; Conditions</span
      >
    </h2>
    <p style="background-color: #ffffff; margin: 12pt 0pt; text-align: center">
      <span style="font-family: Nunito; font-size: 13pt; font-weight: bold"
        >Last Updated: October 1, 2021</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt"
        >These General Terms and Conditions (these “Terms and Conditions”)
        govern the access and use of the mobile applications, including the Roxe
        mobile applications referred to as “Roxe App”, products, software,
        websites, APIs and other services (collectively, our “Platform”) and,
        where applicable, other service providers available on or through the
        Platform of Roxe Inc (“Roxe”).
      </span>
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt"
        >By using the Platform, you agree to follow and be bound by these Terms
        and Conditions and any policies referenced herein and/or applicable,
        including, without limitation, our Privacy Policy.</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt"
        >We may modify these Terms and Conditions or any additional terms that
        apply to the Platform to, for example, reflect changes to the law or
        changes to the Platform. You should look at these Terms and Conditions
        regularly and your continued use of the Platform will constitute your
        acceptance of any revisions to these Terms and Conditions. We will post
        a notice of modified additional terms in the applicable service. If you
        do not agree to the modified terms for the Platform or any service we
        offer, you should discontinue your use of the Platform or that
        service.</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt"
        >In addition to these Terms and Conditions, your use of specific Roxe
        products and services are also governed by other applicable terms and
        agreements available on our website (the “Roxe Site”) or delivered to
        you (collectively, “User Agreements”). In the event of conflict between
        any User Agreement and these Terms and Conditions, the User Agreement
        shall control. These Terms and Conditions and all User Agreements are
        subject to the Prohibited Uses.</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt">1.</span
      ><span
        style="width: 25.24pt; text-indent: 0pt; display: inline-block"
      ></span
      ><span style="font-family: Nunito; font-size: 13pt">General</span>
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 13pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >When used in these Terms and Conditions, the words “you” or “your” mean
        the owner of the Account (as defined below)</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >Roxe Inc is a provider of financial technology available in certain
        U.S. States and other international countries and regions. Roxe Inc is
        registered as a Money Service Business with FinCen in the United States
        (MSB Registration Number: 31000181349472) and with FINTRAC in Canada
        (MSB Registration Number: M21437097). All of Roxe Inc’s licenses are set
        forth on our Regulators page.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >Roxe does not engage in any custodian services or the activity of
        transmitting user funds in the jurisdictions where we do not hold an
        active money transmittance license. We are also not a bank or a
        regulated depository institution. You cannot establish a deposit account
        with Roxe.
      </span>
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 13pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >We use third-party partners, which are properly licensed and authorized
        in such U.S. States and other regions, to perform any banking and money
        transmittance services for Roxe.</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt">2.</span
      ><span
        style="width: 25.24pt; text-indent: 0pt; display: inline-block"
      ></span
      ><span style="font-family: Nunito; font-size: 13pt">Becoming a User</span>
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 13pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >In order to use certain aspects of the Platform, you must set up an
        account as a user (an “Account”), subject to the User Agreement and any
        other applicable agreement with us.
      </span>
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >In creating an Account, you will be asked to set up an email and
        password, and you will be required to set up two-factor authentication
        (“2FA”) By signing up for our service and accepting these terms, by
        providing a mobile phone number you consent that Roxe may contact you by
        SMS or text message (including by an automatic telephone dialing system)
        at the mobile phone number you associate with your account for the
        following:</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >Initial phone number verification</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >70648 2FA via SMS unless you chose an alternative method of 2FA</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 13pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >Account management and transactional notifications and communications
        only.
      </span>
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt"
        >Your phone number may be used for marketing messages only if you have
        expressly consented for such marketing. The Platform may also permit you
        to log into your Account using third party login credentials via a
        single sign-on (“SSO”) service. If you use an SSO service for accessing
        your Account and you terminate your relationship with the relevant SSO
        service provider, you will only be able to access your Account via your
        email and password until you authorize a different SSO service. Your
        email, password, 2FA credentials, and/or SSO credentials are referred to
        collectively as “Login Credentials”. You agree that you are responsible
        for all activities that occur under your Login Credentials. You are
        responsible for maintaining the confidentiality of your Login
        Credentials and you agree not to share your Login Credentials with any
        unauthorized parties. You also agree to notify us promptly of any
        unauthorized use of your Login Credentials or any other breach of
        security that you become aware of involving or relating to the Platform
        by contacting our customer support team.
      </span>
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 13pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >You can cancel the SMS service at any time. Just text "STOP" to the
        short code. After you send the SMS message "STOP" to us, we will send
        you an SMS message with instructions on how to cancel SMS.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >If you are experiencing issues with the messaging program you can reply
        with the keyword HELP for more assistance, or you can get help directly
        at support@roxe.io or by calling (800) 851-5339.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >Carriers are not liable for delayed or undelivered messages.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >As always, message and data rates may apply for any messages sent to
        you from us and to us from you. By providing your consent to the SMS
        service, you approve any such rates and charges from your mobile
        provider and agree that Roxe is not responsible for such rates and
        charges. If you have any questions about your text or data plan, please
        contact your mobile provider. Message frequency is dependent on platform
        usage and will follow the following methodology- one message per request
        for 2FA, and one message per account notification.
      </span>
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >To the maximum extent permitted by applicable law, you hereby agree
        that Roxe shall not be liable for any direct, indirect, consequential,
        special, incidental, punitive or any other damages, even if we have been
        advised of the possibility of such damage or loss, arising or resulting
        from or in any way relating to your use of the SMS service. Furthermore,
        Roxe shall not be liable for the acts or omissions of third parties,
        including but not limited to delays in the transmission of
        messages.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >If you have any questions regarding privacy, please read our Privacy
        Policy.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 13pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >We are required to identify users for legal, regulatory and compliance
        purposes prior to you using our Platform. To ensure we remain in
        compliance with applicable reporting requirements in the jurisdictions
        in which we or our partners operate, we may collect and verify your
        personal information including your legal name, address, government
        identification, date of birth, Social Security or taxpayer
        identification number, bank information or credit card information. We
        may also ask questions and verify information ourselves or obtain
        personal information from third parties in order to verify your
        identity, prevent fraud, as well as requesting information from other
        entities, like banks, for information about you. By agreeing to these
        Terms and Conditions, you certify under penalty of perjury that the
        information provided, including your social security or taxpayer
        identification number, is correct and you agree that we may use the
        information you provide and other information to verify who you
        are.</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt"
        >You additionally acknowledge and agree that we may make any inquiries
        that we consider necessary, either directly or through third parties,
        concerning your identity including, without limitation, requiring you to
        take steps to confirm ownership of your email address or financial
        instruments, ordering a credit report, or verifying information against
        third party databases or through other sources. These inquiries and
        verification may involve the sharing of certain aspects of your
        information with third parties for the limited purpose of completing
        these inquiries and verification.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 13pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >We do not share your information unless required to do so by law and/or
        unless we have your express consent to do so or have another legal basis
        for sharing such information, </span
      ><span style="font-family: Nunito; font-size: 13pt; font-style: italic"
        >e.g</span
      ><span style="font-family: Nunito; font-size: 13pt"
        >., to complete a transaction requested by you, or to prevent fraud. If
        we share your information with any third party we will do so under our
        Privacy Policy. Information about how we may engage with law enforcement
        officials is available here. We may contact any other financial
        institution, law enforcement or affected third parties (including other
        users) and share details of any transactions you are associated with if
        we believe doing so may prevent financial loss or a violation of law.
        Additional information is available in our Privacy Policy.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >If you are setting up an Account on behalf of an entity, you confirm
        that you are an authorized officer of that entity and have the authority
        to enter into agreements for and on behalf of that entity. If you are
        opening an Account on the Platform for an entity, you are hereby
        providing us with written instructions and authorization in accordance
        with the Fair Credit Reporting Act to obtain your personal and/or
        business credit report from a credit bureau (if applicable). You also
        authorize us to obtain your personal and/or business credit report when
        you request certain new products or at any time we reasonably believe
        there may be an increased level of risk associated with your
        Account.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >You represent and warrant to us and agree that the following statements
        are and will remain true:
      </span>
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >All information you provide to us will be complete and accurate and you
        commit to keeping all information complete and accurate at all
        times.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >The information about your address and residency is correct and you
        will notify us if it changes.
      </span>
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >You will provide us with your legal identity and any supporting
        information and documents that we may request, including but not limited
        to a copy of your driver’s license and a copy of your passport.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >You reside in, and will only access the Platform from, a country or
        state where we permit the Platform to be accessed.
      </span>
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >Your use of the Platform complies with all applicable laws and
        regulations, including all export controls and economic sanctions
        requirements.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >You are at least 18 years old and have the capacity to enter into a
        legally binding agreement.
      </span>
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >You will not engage in any Prohibited Use.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >You are not in, under the control of, or a national or resident of, and
        will not supply any funds, product or service to a resident of Cuba,
        Iran, North Korea, Sudan, Syria, Ukraine (Crimea Region), Venezuela or
        any other country or jurisdiction subject to U.S. embargo, U.N.
        sanctions, H.M. Treasury's financial sanctions regime, or E.U.
        sanctions.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >You are not on, acting for anyone on, and will not supply any funds or
        any products or services to Cuba, Iran, North Korea, Sudan, Syria,
        Ukraine (Crimea Region), Venezuela or any other country or jurisdiction
        subject to U.S. embargo, U.N. sanctions, H.M. Treasury's financial
        sanctions regime, or E.U. sanctions, or to anyone on: the Specially
        Designated Nationals List, the U.S. Commerce Department's Denied Persons
        List, Unverified List, or Entity List, HM Treasury's financial sanctions
        regime,  the UN Consolidated United Nations Security Council Sanctions
        List, or EU financial sanctions list.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >You will not access the Platform using automated means of any nature
        without our prior consent. For anyone using our Platform by way of an
        API, please see our API Agreement available on the Roxe Site (“API
        Agreement”).</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >You will not send, use or upload any scripts, viruses or malicious
        code. You will not develop extensions, plugins or applications except as
        may be allowed under our API Agreement.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 13pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >You will not do anything that could disable, overburden or impair the
        functionality, rendering or appearance of the Platform, including
        through denial of service or other attack.</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt">3.</span
      ><span
        style="width: 25.24pt; text-indent: 0pt; display: inline-block"
      ></span
      ><span style="font-family: Nunito; font-size: 13pt"
        >Use of the Platform</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 13pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >We grant you a limited, nonexclusive, non-transferable,
        non-sublicensable license to access and use the Platform solely for
        informational, transactional, or other approved purposes and reserve all
        other rights in the Platform, including without limitation, the Roxe
        Site, APIs and other content. You agree you have no other rights beyond
        this limited license. You agree you will not copy, transmit, distribute,
        sell, resell, license, de-compile, reverse engineer, disassemble,
        modify, publish, participate in the transfer or sale of, create
        derivative works from, perform, display, incorporate into another
        website, or in any other way exploit any content or other part of the
        Platform for any purpose. You also agree that you will not frame or
        display any part of the Platform without our prior written permission
        and that you will not use our trademarks without our permission.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >You understand and agree that the Platform is not provided to, and may
        not be used by, any person in any jurisdiction where the provision or
        use thereof would violate applicable laws and regulations. If you reside
        in any jurisdiction where the use of the Platform would violate any of
        the laws or regulations of the jurisdiction in which you reside, you
        agree to refrain from using the Platform in any manner that would
        violate any of the laws and regulations of such jurisdiction.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >You may only create, have, administer and/or control one Account. If
        you require an additional Account you must first get our written
        permission to do so. You may request an additional Account through our
        support contact form with the proposed purpose for an additional
        Account. We are under no obligation to approve any additional Account.
        If we find that you have opened, operated and/or are operating an
        additional Account, or more than one Account at any one time without our
        permission we reserve all rights, including but not limited to,
        terminating your Account, revoking your limited license to use the
        Platform and taking whatever other means we deem necessary to protect
        our users, us and the Platform.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 13pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >Your use of the Platform, or any part thereof, is at your sole risk and
        we assume no responsibility for the underlying transaction of funds, or
        the actions or identity of any transfer recipient or sender. If any
        third party files a claim for a chargeback or we are otherwise informed
        of a dispute between you and another party, we are not responsible for
        intermediating, determining the veracity of claims or resolving the
        dispute, including disposition of any associated economic value of any
        assets.</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt">4.</span
      ><span
        style="width: 25.24pt; text-indent: 0pt; display: inline-block"
      ></span
      ><span style="font-family: Nunito; font-size: 13pt"
        >Adding Funds on the Platform</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 13pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >We want the Platform to be convenient, so we may allow you to fund your
        transaction(s) or Account using different financial instruments and/or
        accounts. When you fund your transaction(s) or Account, you confirm that
        you are permitted to use the associated financial instrument and/or
        account and you authorize us (and any designated payment processor or
        other service provider) to charge the full amount to the financial
        instrument and/or external account you designate. You also authorize us
        to collect and store that funding instrument, along with other related
        transaction information.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >With respect to funding your transaction(s) or Account in jurisdictions
        where we are not licensed, as indicated on our Roxe Inc Regulators page,
        such Account funding activities are undertaken by our financial
        institution and banking partners. By entering these Terms and
        Conditions, you agree to any terms and conditions of our financial
        institution and banking partners.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >We may partner with other third-party service providers to offer users
        different funding options and financial instruments. By registering for
        and/or using any third-party providers, you expressly agree that you
        have read, understand and accept all the applicable terms and conditions
        that may apply.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >For regulatory and compliance reasons, we reserve the right to refuse
        to process, cancel or reverse, any add-funds transaction(s) if we
        suspect the transaction(s) may involve illicit activity like money
        laundering, terrorist financing, fraud, any crime, or as required by
        facially valid legal process, or relates to a Prohibited Use. We reserve
        the right to report, suspend and/or terminate Accounts for such
        suspected activity.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >You expressly agree that you will not reverse or attempt to reverse any
        transaction(s) to add funds to your Account that you complete. You also
        agree that we may deduct any amounts associated with your Account if an
        add funds transaction is subsequently reversed via a chargeback,
        reversal, claim, or is otherwise deemed fraudulent or erroneous. We may
        also deduct any amounts held by you in your Account to satisfy any
        outstanding amount owed by you to us under these Terms and Conditions or
        otherwise. We reserve the right to report, suspend and/or terminate
        Accounts for chargeback abuse.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 13pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >Your bank may charge you non-sufficient funds (“NSF”) or overdraft fees
        if you do not have sufficient funds to complete a transaction. You are
        responsible for maintaining an adequate balance in your Account and
        sufficient credit limits on your credit cards in order to avoid any
        overdraft, NSF or similar fees. Any and all NSFs are your sole
        responsibility and we shall not be liable or responsible for any NSFs,
        including any fees incurred or other costs to you for any NSFs. Roxe
        reserves the right to submit a pre-authorized ACH transfer up to two
        times in order to collect the funds.</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt">5.</span
      ><span
        style="width: 25.24pt; text-indent: 0pt; display: inline-block"
      ></span
      ><span style="font-family: Nunito; font-size: 13pt"
        >Unsupported Assets</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 13pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >Any attempt to transfer any currency or other asset that Roxe
        technology does not support and Roxe does not formally recognize with
        respect to the Platform (“Unsupported Assets”), whether through forks,
        airdrops, user error, OMNI layer protocols or otherwise, or any funds
        received via initial coin offering distributions, will be not accepted
        by Roxe on behalf of any user, and, subject to the discretion of Roxe,
        you will not receive any credit with respect to any such Unsupported
        Assets.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >In the event that an Unsupported Asset is sent to Roxe by a third party
        or user, by error or otherwise, it is effectively lost to such third
        parties or users. Any such Unsupported Assets will be treated as
        property of Roxe and may be disposed of in accordance with Roxe policy.
        Roxe is not responsible for the recovery or return of any Unsupported
        Asset sent to Roxe by any third party or user, regardless of the
        intention of the person sending such Unsupported Asset or attempts by
        Roxe to return such Unsupported Asset. Roxe will not be responsible for
        or be required to attempt to recover or provide funds with respect to
        any Unsupported Assets sent to the Platform, unless it decides to do so
        in its sole and absolute discretion.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 13pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >Roxe reserves the right in its sole and absolute discretion without
        notice to modify what is an Unsupported Asset at any time. Should Roxe
        determine that any asset is an Unsupported Asset, Roxe will make
        reasonable attempts prior to effecting such change to credit you with
        the amount of the Unsupported Asset held, less Roxe’s cost of such
        conversion, if any. If Roxe is unable to convert an Unsupported Asset
        into a supported asset, whether due to legal or regulatory restrictions,
        the lack of an available market, or otherwise, you may lose the value of
        such Unsupported Asset.</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt">6.</span
      ><span
        style="width: 25.24pt; text-indent: 0pt; display: inline-block"
      ></span
      ><span style="font-family: Nunito; font-size: 13pt"
        >Termination or Cancellation</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 13pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >In the event that you want to stop using the Platform, you can cancel
        your Account if you are in good standing and compliant with these Terms
        and Conditions and other applicable Roxe policies and agreements. To
        cancel, simply remove all funds in your Account and visit our
        cancellation page. We will decide whether to cancel or suspend any
        pending transactions and we will need to hold funds until any applicable
        reversal windows are complete and all your obligations to us are
        satisfied. Of course, you may not cancel your Account in an effort to
        avoid paying amounts due us or if your activity is under investigation.
        Note that withdrawals are subject to minimum balances; contact Roxe
        support if you are not able to cancel your account.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 13pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >In some cases we may terminate, suspend or otherwise restrict your
        Account and use of the Platform if we suspect your Account is connected
        to any Prohibited Use, for suspected violation of these Terms and
        Conditions or any applicable agreements or policies, where required by
        applicable law, or to otherwise prevent potential loss. Your use of the
        Platform is a privilege, and not a right, and we reserve our right to
        terminate, suspend or restrict your access to the Platform, as well as
        take other actions described in these Terms and Conditions, at any time
        to protect you, other users and/or us as we deem necessary.</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt">7.</span
      ><span
        style="width: 25.24pt; text-indent: 0pt; display: inline-block"
      ></span
      ><span style="font-family: Nunito; font-size: 13pt"
        >Inactive Accounts</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 13pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >In the event Roxe is holding funds in your Account, and Roxe has deemed
        the account inactive by no record of you transacting on the Platform for
        more than 90 days, applicable law states Roxe may be required to report
        these funds as unclaimed property to the State where your account is
        registered. In the event this occurs, Roxe will attempt to locate you
        via email and at the physical address on record, however, if Roxe is
        unable to locate you, funds may be surrendered to the applicable
        jurisdiction as unclaimed.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >Roxe will attempt to communicate to you prior to your Account becoming
        inactive that your Account will be debited if your Account remains
        inactive past the 90 days anniversary, as required by applicable
        law.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >An Account may be considered inactive if there are no transactions
        initiated by the user after 90 days, in which case Roxe may in its sole
        discretion, unless otherwise required by law, close inactive accounts
        with zero balances. A transaction is considered any action in which
        value is moved and is initiated by a user within their Account,
        including a bank transfer (deposit or withdrawal).</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 13pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >To the extent required by law, if you leave a balance unused for the
        period of time set forth by your state, country, or other governing body
        in its unclaimed property laws, if you cancel your Account and leave a
        balance, or if we deactivate your Account and you do not meet any
        conditions necessary to reinstate it within six (6) months, we may close
        your Account and send your balance to any mailing address you provide
        using the Platform or, if required, escheat (send) your balance to your
        jurisdiction of residency (based on the jurisdiction associated with
        that mailing address).</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt">8. </span
      ><span
        style="width: 21.88pt; text-indent: 0pt; display: inline-block"
      ></span
      ><span style="font-family: Nunito; font-size: 13pt">Taxes</span>
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 13pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >To the extent applicable, you are responsible for and will pay any
        applicable taxes, duties and fees applicable to activities in your
        Account. Any and all taxes, including any interest and penalties with
        respect thereto, which may be assessed under present or future laws in
        connection with your Account will be paid by you.
      </span>
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >If you are a United States person, as a condition of using our Platform
        you certify under penalties of perjury that the Social Security number
        or Employer Identification Number provided is correct.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 13pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >We do not provide tax or any investment advice and you agree that you
        will determine any tax implications associated with your use of, and any
        transactions you may make using the Platform. You should consult an
        accountant, lawyer or tax authorities in your jurisdiction to determine
        any tax consequences.</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt">9. </span
      ><span
        style="width: 21.88pt; text-indent: 0pt; display: inline-block"
      ></span
      ><span style="font-family: Nunito; font-size: 13pt"
        >Third-Party Services and Applications</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 13pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >We may, from time-to-time, contract with third parties who may provide
        services to Roxe, including financial products and other services in
        connection with the Platform. To the extent required by applicable law,
        regulation or any agreement with such providers, we will provide and
        update a list of relevant financial service partners through the
        Platform. Contracting with other financial service partners or providers
        may require us to share your personal information in order for them to
        provide services to Roxe. All collection, sharing and use of personal
        information is subject to our Privacy Policy.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >You may decide to link to third-party applications or content others
        make available through the Platform. As such, you expressly authorize us
        to work with third-party service providers, including SSO services and
        providers of financial products and services, to deliver certain
        Platform functionality including, without limitation, enabling the
        loading, transfer, conversion and storage of funds. You should carefully
        consider both the functionality purportedly offered and the developer
        offering the functionality before authorizing the application. Unless
        otherwise stated, we have no control over applications and assume no
        responsibility for developers’ actions.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >If you authorize a third-party application to connect to your Account
        and take actions on your behalf, you assume all risks associated with
        those connections and actions and you are ultimately responsible to us
        for those connections and actions. We require developers to list a
        support or dispute framework in connection with their application that
        allows prompt resolution of any issues. But, if you have disputes with
        developers, you will need to resolve it directly with them.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >You also agree that you will not hold us responsible for, and will
        indemnify us against any liability arising out of or related to any act
        or omission of any third-party using your Account credentials whether a
        developer or otherwise. You may remove permissions granted to third
        parties with respect to your Account at any time through your account
        settings (authorized applications) page.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 13pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >A third party not affiliated with us may provide you access to market
        data and quotes to be used alongside the Platform. If market data and
        quotes are provided to you by a third party, your use of the market data
        and quotes will be governed by the terms and conditions imposed by such
        third party.</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt">10. </span
      ><span
        style="width: 14.08pt; text-indent: 0pt; display: inline-block"
      ></span
      ><span style="font-family: Nunito; font-size: 13pt"
        >Electronic Communications</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 13pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >We may be required to provide certain legal and regulatory disclosures,
        periodic statements and confirmations, notices and other communications
        (collectively “Communications”) to you in written form. By agreeing to
        these Terms and Conditions, you consent to Roxe delivering such
        Communications to you in electronic form. Consent for electronic
        delivery applies to every year the statements are furnished. If you no
        longer have access to your account to receive the disclosures in
        electronic format then you may request the disclosures in written
        form.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >By agreeing to these Terms and Conditions, you agree that all
        Communications from us relating to your use of or access to the Platform
        may be provided or made available to you electronically by email, text
        messaging (SMS), “in-app” messaging or by posting a Communication on the
        Platform, and that you can access the Communications in the designated
        formats described below. Your consent to receive Communications and do
        business electronically, and our agreement to do so, applies to all of
        your interactions and transactions with us and our agents concerning
        your Platform usage and access.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >Electronic Communications shall be deemed to be received by you upon
        delivery in the following manner:</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >Posting them to your Account on the Roxe Site or in an associated
        mobile application;</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >Posting them on or in a website or mobile application associated with
        us or the Platform;</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >Sending them via electronic mail to the email address you used to
        register for the Platform;
      </span>
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >Sending them via text messaging (SMS) to the phone number you used to
        register for the Platform; or</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >Otherwise communicating them to you via the Platform.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >It is your responsibility to open and review Communications that we
        deliver to you through the methods described above. We may, but are not
        obligated to provide you with notice of the availability of a
        Communication that is delivered in one of the methods described above
        (for example, by informing you of such Communication through a
        notification sent to your mobile device).</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >It is your responsibility to keep your primary email address and mobile
        number registered with us up to date so that we can communicate with you
        electronically. You understand and agree that if we send you an
        electronic Communication but you do not receive it because your primary
        email address or mobile number registered with us is incorrect, out of
        date, blocked by your service provider, or you are otherwise unable to
        receive electronic Communications, we will be deemed to have provided
        the Communication to you. You can update your primary email address and
        other contact information by submitting a Support request or by logging
        into the Platform and navigating to your profile.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >Please note that if you use a spam filter that blocks or re-routes
        emails from senders not listed in your email address book, you may need
        to add us to your email address book so that you will be able to receive
        the Communications we send to you.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >If you are requesting additional copies of Communications or
        withdrawing your consent, the following additional terms will
        apply:</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >You may contact us, or any applicable service provider, to request
        another electronic copy of the electronic Communication without a
        fee;</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >You may request a paper copy of such electronic Communication within
        ninety (90) days of the original Communication issuance date, and we
        reserve the right to charge a fee to provide such paper copy;</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >You may contact us to update your registration information used for
        electronic Communications or to withdraw consent to receive electronic
        Communications;</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 13pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >We reserve the right to terminate your use of the Platform and any
        associated service provider products and services if you decline or
        withdraw consent to receive electronic Communications.</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt">11. </span
      ><span
        style="width: 14.08pt; text-indent: 0pt; display: inline-block"
      ></span
      ><span style="font-family: Nunito; font-size: 13pt"
        >Roxe Site Accuracy</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 13pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >Although we intend to provide accurate and timely information on the
        Roxe Site, the Roxe Site and, without limitation, any related content,
        materials and information, including without limitation, all market data
        (collectively, the “Content”) may not always be entirely accurate,
        complete or current and may also include technical inaccuracies or
        typographical errors.
      </span>
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >To continue to provide you with as complete and accurate information as
        possible, information may be changed or updated from time to time
        without notice, including information regarding our policies, products
        and services. Accordingly, you should verify all information before
        relying on it, and all decisions based on information contained on the
        Roxe Site are your sole responsibility and we shall have no liability
        for such decisions.
      </span>
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 13pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >Links to third-party materials (including without limitation websites)
        may be provided as a convenience but are not controlled by us. You
        acknowledge and agree that we are not responsible for any aspect of the
        information, content, or services contained in any third-party materials
        or on any third-party sites accessible or linked to the Roxe Site.</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt">12. </span
      ><span
        style="width: 14.08pt; text-indent: 0pt; display: inline-block"
      ></span
      ><span style="font-family: Nunito; font-size: 13pt"
        >Indemnification; Roxe’s Rights and Remedies</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 13pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >If anyone brings a claim against Roxe, our affiliates or service
        providers, and/or each of our or their respective officers, directors,
        agents, joint venturers, employees or representatives, related to your
        use of the Platform, or any part thereof, including, without limitation,
        any developer tools or alleged violation of any laws, rules or rights,
        you indemnify and hold us harmless from and against all damages, losses,
        and expenses of any kind (including reasonable legal fees and costs)
        related to such claim.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >You are responsible for all claims, fees, fines, penalties and other
        liability incurred by Roxe or a third party caused by or arising out of
        your breach of these Terms and Conditions or any other applicable Roxe
        policy and agreement, and/or your use of the Platform. You agree to
        reimburse Roxe or a third party for any and all such liability and any
        fees and expenses incurred in the event that Roxe must undertake
        collection efforts to enforce its rights hereunder.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 13pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >If you engage in a Prohibited Use or otherwise violate these Terms and
        Conditions or any other applicable Roxe policy and agreement, including
        but not limited to chargeback abuse, fraud, money laundering, sale of
        your login credentials, etc., it may be difficult or impractical to
        calculate our actual damages. You acknowledge and agree that US$10,000
        USD per violation is a reasonable minimum estimate of Roxe's actual
        damages, considering all currently existing circumstances, including the
        relationship of the sum to the range of harm to Roxe that reasonably
        could be anticipated, and that Roxe may recover from you and/or directly
        from your Account the greater of such amount or the actual damages
        incurred.</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt">13. </span
      ><span
        style="width: 14.08pt; text-indent: 0pt; display: inline-block"
      ></span
      ><span style="font-family: Nunito; font-size: 13pt">Disclaimer</span>
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 13pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >WE TRY TO KEEP THE PLATFORM UP, BUG-FREE, AND SAFE, BUT YOU USE IT AT
        YOUR OWN RISK. THE PLATFORM IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE"
        BASIS WITHOUT ANY REPRESENTATION OR WARRANTY, WHETHER EXPRESS, IMPLIED
        OR STATUTORY. WE SPECIFICALLY DISCLAIM ANY IMPLIED WARRANTIES OF TITLE,
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.
        WE DO NOT MAKE ANY REPRESENTATIONS OR WARRANTIES THAT ACCESS TO ANY PART
        OF THE PLATFORM, OR ANY OF THE MATERIALS CONTAINED THEREIN, WILL BE
        CONTINUOUS, UNINTERRUPTED, TIMELY, ERROR-FREE, OR SECURE. OPERATION OF
        THE PLATFORM MAY BE INTERFERED WITH BY NUMEROUS FACTORS OUTSIDE OF OUR
        CONTROL. WE MAKE NO REPRESENTATIONS OR WARRANTIES CONCERNING THE REAL OR
        PERCEIVED VALUE OF ANY CURRENCY. WE MAKE NO REPRESENTATION OR WARRANTIES
        AS TO THE QUALITY, SUITABILITY, USEFULNESS, ACCURACY, OR COMPLETENESS OF
        THE PLATFORM OR ANY MATERIALS CONTAINED THEREIN. WE WILL MAKE REASONABLE
        EFFORTS TO ENSURE THAT ANY REQUESTS FOR ELECTRONIC DEBITS AND CREDITS BY
        ANY MEANS ARE PROCESSED IN A TIMELY MANNER BUT WE MAKE NO
        REPRESENTATIONS OR WARRANTIES REGARDING THE AMOUNT OF TIME NEEDED TO
        COMPLETE PROCESSING.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >YOU WAIVE ANY RIGHT TO ASSERT ANY CLAIMS AGAINST ANY ROXE ENTITY AS A
        REPRESENTATIVE OR MEMBER IN ANY CLASS OR REPRESENTATIVE LEGAL
        PROCEEDING, INCLUDING, WITHOUT LIMITATION, CLASS OR COLLECTION
        LITIGATION OR ARBITRATION, EXCEPT WHERE SUCH WAIVER IS PROHIBITED BY LAW
        OR DEEMED BY A COURT OF LAW TO BE AGAINST PUBLIC POLICY. TO THE EXTENT
        YOU ARE PERMITTED BY LAW OR COURT OF LAW TO PROCEED WITH A CLASS OR
        REPRESENTATIVE LEGAL PROCEEDING, YOU AGREE THAT: (I) YOU SHALL NOT BE
        ENTITLED TO RECOVER ATTORNEYS’ FEES OR COSTS ASSOCIATED WITH PURSUING
        THE CLASS OR REPRESENTATIVE ACTION (NOTWITHSTANDING ANY OTHER PROVISION
        IN THIS AGREEMENT); AND (II) IF YOU INITIATE OR PARTICIPATE AS A MEMBER
        OF THE CLASS, YOU WILL NOT SUBMIT A CLAIM OR OTHERWISE PARTICIPATE IN
        ANY RECOVERY SECURED THROUGH THE CLASS OR REPRESENTATIVE ACTION.
      </span>
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >WE ARE NOT RESPONSIBLE FOR THE ACTIONS, CONTENT, INFORMATION, OR DATA
        OF THIRD PARTIES, AND YOU RELEASE US, OUR DIRECTORS, OFFICERS,
        EMPLOYEES, AND AGENTS AND OUR AFFILIATES AND SERVICE PROVIDERS, OR ANY
        OF THEIR RESPECTIVE OFFICERS, DIRECTORS, AGENTS, JOINT VENTURERS,
        EMPLOYEES OR REPRESENTATIVES, FROM ANY CLAIMS AND DAMAGES, KNOWN AND
        UNKNOWN, ARISING OUT OF OR IN ANY WAY CONNECTED WITH ANY CLAIM YOU HAVE
        AGAINST ANY SUCH THIRD PARTIES.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >IF YOU ARE A CALIFORNIA RESIDENT, YOU WAIVE CALIFORNIA CIVIL CODE
        §1542, WHICH SAYS: A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE
        CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE
        TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE
        MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >NEITHER WE NOR OUR AFFILIATES, SERVICE PROVIDERS, OR OUR OR THEIR
        RESPECTIVE OFFICERS, DIRECTORS, AGENTS, JOINT VENTURERS, EMPLOYEES OR
        REPRESENTATIVES WILL BE LIABLE TO YOU FOR ANY CONSEQUENTIAL, SPECIAL,
        INDIRECT, OR INCIDENTAL DAMAGES ARISING OUT OF OR IN CONNECTION WITH
        THESE TERMS AND CONDITIONS OR THE PLATFORM, EVEN IF WE HAVE BEEN ADVISED
        OF THE POSSIBILITY OF SUCH DAMAGES. OUR AGGREGATE LIABILITY, AND THAT OF
        OUR AFFILIATES AND SERVICE PROVIDERS, OR ANY OF OUR OR THEIR RESPECTIVE
        OFFICERS, DIRECTORS, AGENTS, JOINT VENTURERS, EMPLOYEES OR
        REPRESENTATIVES, TO YOU OR ANY THIRD PARTIES IN ANY CIRCUMSTANCE IS
        LIMITED TO THE GREATER OF (A) THE ACTUAL FEES PAID TO US BY YOU IN THE
        PRECEDING THREE (3) MONTHS OR (B) US$100 DOLLARS. APPLICABLE LAW MAY NOT
        ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY OR INCIDENTAL OR
        CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT
        APPLY TO YOU. IN SUCH CASES, OUR LIABILITY WILL BE LIMITED TO THE
        FULLEST EXTENT PERMITTED.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 13pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >WE ARE NOT A MEMBER OF ANY FINANCIAL INDUSTRY REGULATORY AUTHORITY, AND
        YOUR FUNDS ARE NOT PROTECTED OR COVERED BY THE FEDERAL DEPOSIT INSURANCE
        CORPORATION OR ANY OTHER INSURANCE.</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt">14. </span
      ><span
        style="width: 14.08pt; text-indent: 0pt; display: inline-block"
      ></span
      ><span style="font-family: Nunito; font-size: 13pt">Other Matters</span>
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 13pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >Our failure to act with respect to a breach by you or others does not
        waive our right to act with respect to subsequent or similar breaches.
        These Terms and Conditions shall not be construed to waive rights that
        cannot be waived under applicable consumer protection laws or
        regulations.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >Unless otherwise agreed in writing, these Terms and Conditions, any
        other applicable Roxe policy and agreement (including the User
        Agreement, and, if applicable, our API Agreement) referred to herein or
        therein, and the terms and conditions contained in your Account
        statements and confirmations, set forth the entire understanding and
        agreement between you and us as to the subject matter hereof, and
        supersedes any and all prior discussions, agreements and understandings
        of any kind (including without limitation any prior versions of these
        Terms and Conditions), and of every nature between and among you and
        us.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >These Terms and Conditions, and any rights, obligations and licenses
        granted hereunder, may not be transferred or assigned by you, but may be
        assigned by us without notice or restriction, including without
        limitation to any of our affiliates, parents or subsidiaries, or to any
        successor in interest. Any attempted transfer or assignment in violation
        hereof shall be null and void except that, subject to the limits herein,
        our agreement will bind and inure to the benefit of the parties, their
        successors and permitted assigns.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >If any provision of these Terms and Conditions is held by a court to be
        invalid or unenforceable, such provision will be changed and interpreted
        to accomplish the objectives of the provision to the greatest extent
        possible and any such finding shall not affect the enforceability of any
        other provision.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >Except as otherwise expressly provided in these Terms and Conditions,
        there are no third-party beneficiaries to these Terms and
        Conditions.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >Provisions herein related to suspension, termination, cancellation,
        debts, general use of the Platform, fees, disputes, your liability,
        indemnity and general provisions shall survive any termination of these
        Terms and Conditions.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >Any translation or summary of the Platform, these Terms and Conditions
        and/or policies is provided solely as a convenience and is not intended
        to modify the Platform, these Terms and Conditions, and/or any policies.
        You agree that the English version of the Platform, these Terms and
        Conditions and/or policies will control in the event of any conflict
        between the English versions of the Platform, these Terms and Conditions
        and/or policies and any translated versions of the same.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >You agree that the laws of the State of New York, without regard to
        principles of conflict of laws, govern these Terms and Conditions and
        any claim or dispute between you and us except to the extent governed by
        U.S. federal law. You agree that federal and state courts located in New
        York. New York shall have exclusive jurisdiction over any disputes
        arising under these Terms and Conditions and/or any Roxe policies and
        agreements that reference these Terms and Conditions.</span
      >
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 0pt">
      <span style="font-family: DM Sans font-size: 11pt">&#xa0;</span>
    </p>
  </div>
</template>

<style lang="less" scoped>
.WordSection1 {
  padding: 30px;
  background: #fff;
  text-align: left;
  font-family: "DM Sans", Avenir, Helvetica, Arial, sans-serif !important;
}
.MsoNormal {
  font-size: 16px;
  line-height: 24px;
  color: #818e9e;
  background: #fff;
  margin-bottom: 14pt;
}
@media screen and (max-width: 991px) {
  .WordSection1 {
    padding-top: 70px;
  }
}
@media screen and (max-width: 575px) {
  .WordSection1 {
    padding: 70px 20px 80px;
  }
}
</style>
